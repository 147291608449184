import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from 'utils/lodash.utils'

import { COUNTRIES_WITH_STATES } from 'constants/countries.constants'

import { EloInput } from '@elo-ui/components/elo-inputs'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloSelect } from '@elo-ui/components/elo-select'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloRadioButton } from '@elo-ui/components/elo-radio-button'
import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'

import { payerProfileSchema } from 'shared/screens/profile/userProfile.schema'

import { usePayerStore } from '../../../hooks/use-payer-stores'

import { MODALS_IDS } from '../index'

import './user-profile-modal.scss'

const defaultProfileValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  street: '',
  streetNumber: '',
  city: '',
  zip: '',
  phone: '',
  payerCountry: '',
  vatId: '',
  taxNumber: '',
  vatPayer: '',
  registryLocation: '',
  region: '',
  state: '',
  salutation: '',
}

const NO = 'no'
const YES = 'yes'
const VAT_NO = 'vatNo'
const SALUTATION_OPTIONS = [
  {
    value: 'mr',
    label: I18n.t('react.shared.checkout.mr'),
  },
  {
    value: 'mrs',
    label: I18n.t('react.shared.checkout.mrs'),
  },
  {
    value: 'non_binary',
    label: I18n.t('react.shared.checkout.non_binary'),
  },
  {
    value: 'dr',
    label: I18n.t('react.shared.checkout.dr'),
  },
  {
    value: 'none',
    label: I18n.t('react.shared.checkout.none'),
  },
]

interface UserProfileModalProps {
  userProfile?: any
}

const salutationOption = (key) => SALUTATION_OPTIONS.find((item) => item.value === key)

export const UserProfileModal: React.FC = observer(() => {
  const { countriesStore, userProfilesStore, userStore, modalsManager } = usePayerStore()
  const { isOpen, userProfile } = modalsManager.getModal<UserProfileModalProps>(MODALS_IDS.userProfileModal)

  const [errors, setErrors] = useState(defaultProfileValues)
  const [fields, setFields] = useState({ ...defaultProfileValues, vatPayer: YES })

  const handleClose = () => {
    setFields({ ...defaultProfileValues, vatPayer: YES })
    setErrors(defaultProfileValues)
    modalsManager.close(MODALS_IDS.userProfileModal)
  }
  const handleChangeField = async (name, value) => {
    const currentValue = typeof value === 'object' ? value.value : value

    setFields({ ...fields, [name]: currentValue })
    setErrors({ ...errors, [name]: '' })

    if (name === 'payerCountry') {
      const countryHasStates = COUNTRIES_WITH_STATES.includes(currentValue)

      if (countryHasStates) {
        countriesStore.fetchStates(currentValue)
      }
    }

    try {
      await payerProfileSchema.validateAt(name, { [name]: currentValue })
    } catch (error: any) {
      if (error) {
        setErrors({ ...errors, [name]: error?.errors[0] })
      }
    }
  }

  useEffect(() => {
    const isEdit = !!userProfile?.id

    if (isEdit) {
      const currentProfileValues = getInitialValuesFromProfile()

      setFields(currentProfileValues)
    }

    if (!countriesStore.list.length) {
      countriesStore.fetchList()
    }
  }, [userProfile?.id])

  const submitForm = async () => {
    const profileId = userProfile?.id
    const { createItem, updateItem } = userProfilesStore

    const {
      city,
      companyName,
      firstName,
      lastName,
      payerCountry,
      phone,
      registryLocation,
      street,
      streetNumber,
      taxNumber,
      vatPayer,
      zip,
      state,
      salutation,
    } = fields

    const sendState = payerCountry && COUNTRIES_WITH_STATES.includes(payerCountry)

    const profileData = {
      city,
      firstName,
      lastName,
      phone,
      street,
      streetNumber,
      zip,
      company: companyName,
      countryCode: payerCountry,
      registrationLocation: registryLocation,
      taxNo: taxNumber,
      vatNo: '',
      vatPayer: vatPayer === YES,
      // TODO: remove after full implementation of states logic
      region: sendState ? state : '',
      state: sendState ? state : '',
      salutation: salutation,
    }

    const apiCall = () => (profileId ? updateItem(profileId, profileData) : createItem(profileData))

    const { success } = await apiCall()

    if (success) {
      await userProfilesStore.fetchFullList({ userId: userStore?.item?.id, expand: ['user_profile_verification'] })
      handleClose()
    }
  }

  const getInitialValuesFromProfile = () => ({
    firstName: userProfile.firstName || '',
    lastName: userProfile.lastName || '',
    companyName: userProfile.company || '',
    street: userProfile.street || '',
    streetNumber: userProfile.streetNumber || '',
    city: userProfile.city || '',
    zip: userProfile.zip || '',
    phone: userProfile.phone || '',
    payerCountry: userProfile.countryCode || '',
    vatId: userProfile[VAT_NO] || '',
    taxNumber: userProfile.taxNo || '',
    vatPayer: userProfile.vatPayer ? YES : NO,
    registryLocation: userProfile.registrationLocation || '',
    // TODO: remove after full implementation of states logic
    region: userProfile.region || '',
    state: userProfile.state || '',
    salutation: userProfile.salutation || '',
  })

  const allRequiredFields = ['firstName', 'lastName', 'street', 'zip', 'city', 'payerCountry', 'phone'].reduce(
    (filteredObj, key) => {
      if (fields.hasOwnProperty(key)) {
        filteredObj[key] = fields[key]
      }
      return filteredObj
    },
    {}
  )

  const someFieldIsEmpty = Object.values(allRequiredFields).some((field) => isEmpty(field))
  const hasErrors = Object.values(errors).some((error) => !!error)
  const isDisabled = someFieldIsEmpty || hasErrors
  const showStateField = fields.payerCountry && COUNTRIES_WITH_STATES.includes(fields.payerCountry)

  if (!isOpen) return null

  return (
    <EloModal isOpen isOverlayFixed className='user-profile-modal' onClose={handleClose}>
      <EloModalHeader>{I18n.t('react.shared.user_profile')}</EloModalHeader>
      <EloModalBody>
        <div className='user-profile-modal__input-group-1'>
          <EloSelect.Select
            id='user-profile-modal-salutation-select'
            label={I18n.t('react.cabinet.checkout_settings.attrs.salutation')}
            placeholder={I18n.t('react.cabinet.checkout_settings.attrs.salutation')}
            onChange={(value) => handleChangeField('salutation', value)}
            onBlur={() => handleChangeField('salutation', fields.salutation)}
            options={SALUTATION_OPTIONS}
            value={fields.salutation && salutationOption(fields.salutation)}
            error={!!errors?.salutation}
            errorText={errors?.salutation}
          />
          <EloInput
            name='firstName'
            label={I18n.t('react.shared.first_name')}
            placeholder={I18n.t('react.shared.first_name')}
            value={fields.firstName}
            onChange={(e) => handleChangeField('firstName', e.target.value)}
            onBlur={() => handleChangeField('firstName', fields.firstName)}
            error={!!errors?.firstName}
            errorText={errors?.firstName}
            required
          />
          <EloInput
            name='lastName'
            label={I18n.t('react.shared.last_name')}
            placeholder={I18n.t('react.shared.last_name')}
            value={fields.lastName}
            onChange={(e) => handleChangeField('lastName', e.target.value)}
            onBlur={() => handleChangeField('lastName', fields.lastName)}
            error={!!errors?.lastName}
            errorText={errors?.lastName}
            required
          />
        </div>
        <EloInput
          name='companyName'
          label={I18n.t('react.shared.company_name')}
          placeholder={I18n.t('react.shared.company_name')}
          value={fields.companyName}
          onChange={(e) => handleChangeField('companyName', e.target.value)}
          error={!!errors?.companyName}
          errorText={errors?.companyName}
        />
        <div className='user-profile-modal__input-group-2'>
          <EloInput
            name='street'
            label={I18n.t('react.shared.street')}
            placeholder={I18n.t('react.shared.street')}
            value={fields.street}
            onChange={(e) => handleChangeField('street', e.target.value)}
            onBlur={() => handleChangeField('street', fields.street)}
            error={!!errors?.street}
            errorText={errors?.street}
            required
          />
          <EloInput
            name='streetNumber'
            label={I18n.t('react.shared.street_number')}
            placeholder={I18n.t('react.shared.street_number')}
            value={fields.streetNumber}
            onChange={(e) => handleChangeField('streetNumber', e.target.value)}
            error={!!errors?.streetNumber}
            errorText={errors?.streetNumber}
          />
        </div>
        <div className='user-profile-modal__input-group-3'>
          <EloInput
            name='zip'
            label={I18n.t('react.shared.zip')}
            placeholder={I18n.t('react.shared.zip')}
            value={fields.zip}
            onChange={(e) => handleChangeField('zip', e.target.value)}
            onBlur={() => handleChangeField('zip', fields.zip)}
            error={!!errors?.zip}
            errorText={errors?.zip}
            required
          />
          <EloInput
            name='city'
            label={I18n.t('react.shared.city')}
            placeholder={I18n.t('react.shared.city')}
            value={fields.city}
            onChange={(e) => handleChangeField('city', e.target.value)}
            onBlur={() => handleChangeField('city', fields.city)}
            error={!!errors?.city}
            errorText={errors?.city}
            required
          />
        </div>
        <div className='user-profile-modal__input-group-1'>
          <EloSelect.Select
            id='user-profile-modal-country-select'
            label={I18n.t('react.shared.country')}
            placeholder={I18n.t('react.shared.select_country')}
            onChange={(value) => handleChangeField('payerCountry', value)}
            onBlur={() => handleChangeField('payerCountry', fields.payerCountry)}
            options={countriesStore?.countrySelectOptions}
            value={fields.payerCountry ? countriesStore.getCountrySelectOption(fields.payerCountry) : null}
            error={!!errors?.payerCountry}
            errorText={errors?.payerCountry}
            required
          />
          {showStateField && (
            <EloSelect.Select
              id='user-profile-modal-state-select'
              label={I18n.t('react.shared.country_state')}
              placeholder={I18n.t('react.shared.country_state')}
              onChange={(value) => handleChangeField('state', value)}
              onBlur={() => handleChangeField('state', fields.state)}
              options={countriesStore.getStatesSelectOptions(fields.payerCountry)}
              value={fields.state ? countriesStore.getStatesSelectOption(fields.payerCountry, fields.state) : null}
              error={!!errors?.state}
              errorText={errors?.state}
              required
            />
          )}
          <EloInput
            name='phone'
            label={I18n.t('react.shared.phone_number')}
            placeholder={I18n.t('react.shared.phone_number')}
            value={fields.phone}
            onChange={(e) => handleChangeField('phone', e.target.value)}
            onBlur={() => handleChangeField('phone', fields.phone)}
            error={!!errors?.phone}
            errorText={errors?.phone}
            required
          />
        </div>
        <div className='user-profile-modal__radio-group'>
          <div className='user-profile-modal__radio-group__label'>
            <span>{I18n.t('react.shared.vat_payer')}</span>
            <EloTooltip content={I18n.t('react.cabinet.help_icon.liable_for_vat.content')} placement='top' show>
              <EloInfoIcon />
            </EloTooltip>
          </div>
          <div className='user-profile-modal__radio-group__buttons'>
            <EloRadioButton checked={fields.vatPayer === YES} onChange={() => handleChangeField('vatPayer', YES)}>
              {I18n.t('shared.common.yes')}
            </EloRadioButton>
            <EloRadioButton checked={fields.vatPayer === NO} onChange={() => handleChangeField('vatPayer', NO)}>
              {I18n.t('shared.common.no')}
            </EloRadioButton>
          </div>
        </div>
        <div className='user-profile-modal__input-group-1'>
          <EloInput
            name='taxNumber'
            label={I18n.t('react.shared.tax_number')}
            placeholder={I18n.t('react.shared.tax_number')}
            value={fields.taxNumber}
            onChange={(e) => handleChangeField('taxNumber', e.target.value)}
            error={!!errors?.taxNumber}
            errorText={errors?.taxNumber}
          />
          <EloInput
            name='vatId'
            label={I18n.t('react.shared.vat_id')}
            placeholder={I18n.t('react.shared.vat_id')}
            value={fields.vatId}
            onChange={(e) => handleChangeField('vatId', e.target.value)}
            error={!!errors?.vatId}
            errorText={errors?.vatId}
          />
        </div>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton variant='secondary' onClick={handleClose}>
          {I18n.t('react.shared.button.cancel')}
        </EloButton>
        <EloButton disabled={isDisabled} onClick={submitForm}>
          {I18n.t('react.shared.button.save')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
